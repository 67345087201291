// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let stripeTestPubKey: string = 'pk_test_51HPxtkAu44ynQQmHbEwiwH6BR8OhXNqBxDiriW2Mr0JwnwkUPWFFFPzz3SQww2eGwAQCzJJa339VRWpkzPWAMMba00qQ3WXV7R';
let stripeTestSecretKey: string = 'rk_test_51HPxtkAu44ynQQmH5QkzseVKktjHvF1JvxgfZ4xvcHSifDJ1nCIA7ybG0xajck9zr4CrUnehkxDGnklo2bypNjj900jCPuKMuS';
let stripeLivePubKey: string = 'pk_live_51HPxtkAu44ynQQmH1VJbvxTy5g5fil615ZqqWqbmGPaZ6MwjEt8emVftrF8PKWt7J1h8d4IAlDidqQeXLUJS0HeP00h7xYWUJ3';
let stripeLiveSecretKey: string = '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';
let env: boolean = false;

export const environment = {
  production: env,
    // apiBaseUrl: 'https://api.malady.co/v1',             // API_Live
    apiBaseUrl: 'https://api-test.malady.co/v1',     // API_Test
    // apiBaseUrl: 'http://localhost:85/v1',            // local machine API
  redirectSignIn: 'http://localhost:4200/auth/login',
  redirectSignOut: 'http://localhost:4200/login',
  GOOGLE_PLACE_API_KEY: 'google place api key',
  GOOGLE_PROXY_BASE_URL: 'http://localhost:4200',
  routeDashboard: 'https://sandbox-booking.malady.co/patient'
};

export const stripe = {
  stripe_key: env ? stripeLivePubKey : stripeTestPubKey,
  stripe_secret: env ? stripeLiveSecretKey : stripeTestSecretKey,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
